<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="background">
        <div class="w1300 t30">
          <div class="top">
            <div class="top-left">
              <div class="tite">保函订单支付</div>
              <div class="id">订单提交成功，请尽快付款！订单编号：{{applyId}} </div>
            </div>
            <div class="top-right">
              <div class="de">应付金额 <span class="money">{{payAmout}}</span><span class="unit">元</span></div>
            </div>
          </div>
          <div class="content">
            <div v-if="item1" class="item mb20" :class="showGongBox?'':'itemOld'" @click="changeTpey(1)">
              <div class="line1">
                <img src="../../assets/icon1.png" style="width: 60px;" alt="对公转账" class="mr10">
                <div class="pay-type">对公转账</div>
              </div>
              <div class="show-box" v-if="showGongBox">
                <div class="tips">
                  <div class="flex">
                    <img src="../../assets/gth.png" alt=""><span class="zysx">注意事项</span>
                  </div>
                  <div class="tip-content">
                    <p>请一次性转账支付，不能分多次转账；无需填写附言；</p>
                    <p>请使用交易系统中登记的基本户进行转账，非基本户转账将无法通过审核；；</p>
                    <p>转账支付到账有延迟，请不要在临近保证金缴纳截止时间（1小时内）进行转账支付，否则可能存在到账时间超期，无法生成保函风险，如确需支付，相关后果请自负</p>
                    <p>转账支付到账有延迟，请在转账支付后30分钟左右，回到金融服务平台查看保函状态。</p>
                  </div>
                </div>
                <div class="description">
                  <div class="l1 flex-between mb20">
                    <div class="username">
                      <span class="label">银行账户户名：</span><span class="value" :title="obj1.payBankAccount">{{obj1.payBankAccount}}</span>
                    </div>
                    <div class="username">
                      <span class="label">银行账户号码：</span><span class="value" :title="obj1.payBankNo">{{obj1.payBankNo}}</span>
                    </div>
                  </div>
                  <div class="l2 flex-between mb20 column">
                    <div class="username mb20">
                      <span class="label">开户银行：</span><span class="value" :title="obj1.payBankName">{{obj1.payBankName}}</span>
                    </div>
                    <div class="username">
                      <span class="label">开户网点支行：</span><span class="value" :title="obj1.payBankSubName">{{obj1.payBankSubName}}</span>
                    </div>
                  </div>
                  <div class="l3 mb20 " style="display:flex">
                    <span class="label">备注：</span><span class="value1">{{obj1.remark}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item2" class="item mb20" :class="showSiBox?'':'itemOld'" @click="changeTpey(2)">
              <div class="line1">
                <img src="../../assets/icon2.png" alt="线上转账" style="width: 36px;" class="mr20">
                <div class="pay-type">线上转账</div>
              </div>
              <div class="show-box" v-if="showSiBox">
                <div class="tips">
                  <div class="flex">
                    <img src="../../assets/gth.png" alt=""><span class="zysx">注意事项</span>
                  </div>
                  <div class="tip-content">
                    <p>请务必填写附言；</p>
                    <p>请一次性转账支付，不能分多次转账；</p>
                    <p>转账支付到账有延迟，请不要在临近保证金缴纳截止时间（1小时内）进行转账支付，否则可能存在到账时间超期，无法生成保函风险，如确需支付，相关后果请自负；</p>
                    <p>转账支付到账有延迟，请在转账支付后30分钟左右，回到金融服务平查看保函状态。</p>
                  </div>
                </div>
                <div class="description">
                  <div class="l1 flex-between mb20">
                    <div class="username">
                      <span class="label">银行账户户名：</span><span class="value" :title="obj2.payBankNo">{{obj2.payBankAccount}}</span>
                    </div>
                    <div class="username">
                      <span class="label">银行账户号码：</span><span class="value" :title="obj2.payBankNo">{{obj2.payBankNo}}</span>
                    </div>
                  </div>
                  <div class="l2 flex-between mb20 column">
                    <div class="username mb20 w100">
                      <span class="label">开户银行：</span><span class="value" :title="obj2.payBankName">{{obj2.payBankName}}</span>
                    </div>
                    <div class="username w100">
                      <span class="label">开户网点支行：</span><span class="value" :title="obj2.payBankSubName">{{obj2.payBankSubName}}</span>
                    </div>
                  </div>
                  <div class="l3 mb20 w100" style="display:flex">
                    <!-- <div class="remark"> -->
                    <span class="label">备注：</span><span class="value1">{{obj2.remark}}</span>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item3" class="item2 mb20" :class="showZhiBox?'':'itemOld'" @click="changeTpey(3)">
              <div class="line1">
                <img src="../../assets/icon3.png" style="width: 36px;" alt="支付宝支付" class="mr20">
                <div class="pay-type">支付宝支付</div>
              </div>
              <div class="show-box2" v-if="showZhiBox">
                <div class="ewm mr20">
                  <img :src="pay3img" alt="" style="width: 100px;height: 100px;">
                </div>
                <div class="tips">
                  <div class="flex">
                    <img src="../../assets/gth.png" alt=""><span class="zysx">注意事项</span>
                  </div>
                  <div class="tip-content">
                    <p> 注意事项：请扫码支付；</p>
                    <p>支付到账有延迟，请不要在临近保证金缴纳截止时间（1小时内）进行支付，否则可能存在到账时间超期，无法生成保函风险，如确需支付，相关后果请自负；</p>
                    <p>支付到账有延迟，请在转账支付后30分钟左右，回到金融服务平台查看保函状态。</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item4" class="item2 mb20" :class="showWeiBox?'':'itemOld'" @click="changeTpey(4)">
              <div class="line1">
                <img src="../../assets/icon4.png" style="width: 38px;" alt="微信支付" class="mr20">
                <div class="pay-type">微信支付</div>
              </div>
              <div class="show-box2" v-if="showWeiBox">
                <div class="ewm mr20">
                  <img :src="pay4img" alt="" style="width: 100px;height: 100px;">
                </div>
                <div class="tips">
                  <div class="flex">
                    <img src="../../assets/gth.png" alt=""><span class="zysx">注意事项</span>
                  </div>
                  <div class="tip-content">
                    <p> 注意事项：请扫码支付；</p>
                    <p>支付到账有延迟，请不要在临近保证金缴纳截止时间（1小时内）进行支付，否则可能存在到账时间超期，无法生成保函风险，如确需支付，相关后果请自负；</p>
                    <p>支付到账有延迟，请在转账支付后30分钟左右，回到金融服务平台查看保函状态。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { getPayInfo, genQRCode } from "@/api/index.js"
import { ElMessageBox } from 'element-plus'
import { ref, onMounted, reactive } from "vue";
let router = useRouter()
let showGongBox = ref(false)
let showSiBox = ref(false)
let showZhiBox = ref(false)
let showWeiBox = ref(false)
let item1 = ref(false)
let item2 = ref(false)
let item3 = ref(false)
let item4 = ref(false)
let pay3img = ref()
let pay4img = ref()
let applyId = ref()
let payAmout = ref()
let obj1 = reactive({})
let obj2 = reactive({})
let changeTpey = (type) => {
  if (type == 1) {
    showGongBox.value = true
    showSiBox.value = false
    showZhiBox.value = false
    showWeiBox.value = false
  }
  if (type == 2) {
    showSiBox.value = true
    showGongBox.value = false
    showWeiBox.value = false
    showZhiBox.value = false
  }
  if (type == 3) {
    showSiBox.value = false
    showWeiBox.value = false
    showGongBox.value = false
    showZhiBox.value = true
  }
  if (type == 4) {
    showSiBox.value = false
    showZhiBox.value = false
    showGongBox.value = false
    showWeiBox.value = true
  }
}
let fk = async (value) => {
  await getPayInfo({ orderId: value }).then((res) => {
    if (res.code == 200) {
      applyId.value = res.data.orderSn
      payAmout.value = res.data.payAmout
      if (res.data.dataList.length > 0) {
        res.data.dataList.forEach((item) => {
          if (item.paytype == 1) {
            item1.value = true
            obj1 = item
          }
          if (item.paytype == 2) {
            item2.value = true
            obj2 = item

          }
          if (item.paytype == 3) {
            item3.value = true
            genQRCode({ url: item.remark }).then((res) => {
              pay3img.value = 'data:image/png;base64,' + res.data;
            })

          }
          if (item.paytype == 4) {
            item4.value = true
            genQRCode({ url: item.remark }).then((res) => {
              pay4img.value = 'data:image/png;base64,' + res.data;
            })
          }
        })
      }
      if (res.data.payState == 'PAID') {
        ElMessageBox.confirm(
          '该订单已支付成功，请返回订单详情查看！',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showCancelbutton: true
          }
        ).then(() => {
          router.push({ path: '/personalCenter' })
          localStorage.setItem('active', '2')
        }).catch(() => {
          return
        })
      }
    }
  })
}
onMounted(async () => {
  await fk(router.currentRoute.value.query.orderId)
})
</script>
<style  scoped>
.w100 {
  width: 100% !important;
}
.column {
  flex-direction: column;
  align-items: flex-start !important;
}
.top {
  width: 1300px;
  height: 184px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  padding: 50px 100px 50px 50px;
  border-top: 4px solid rgba(0, 82, 217, 1);
  box-sizing: border-box;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tite {
  height: 38px;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 37.65px;
  margin-bottom: 20px;
  color: rgba(32, 35, 50, 1);
}
.id {
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(32, 35, 50, 1);
}
.de {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 43.44px;
  color: rgba(0, 0, 0, 1);
  margin-right: 10px;
}
.money {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 43.44px;
  color: rgba(255, 84, 41, 1);
}
.unit {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 43.44px;
  color: rgba(255, 84, 41, 1);
}

/* img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.t30 {
  margin-top: 30px;
}
.background {
  background: rgb(253, 253, 253);
}
.content {
  width: 1300px;
  height: 804px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 50px;
  box-sizing: border-box;
  margin-top: 30px;
}
.item2 {
  width: 1200px;
  height: 213px;
  padding: 20px 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 82, 217, 1);
}
.item {
  width: 1200px;
  height: 384px;
  padding: 20px 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 82, 217, 1);
}
.itemOld {
  width: 1200px;
  height: 100px;
  padding: 20px 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(231, 231, 231, 1);
}
.line1 {
  display: flex;
  align-items: center;
  height: 60px;
}
.flex {
  display: flex;
  align-items: center;
}
.show-box {
  margin-left: 70px;
  width: 957px;
}
.show-box2 {
  margin-left: 55px;
  width: 957px;
  display: flex;
}
.pay-type {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(32, 35, 50, 1);
}

.tips {
  width: 957px;
  height: 106px;
  margin-bottom: 16px;
}
.zysx {
  /** 文本2 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(212, 48, 48, 1);
  margin-left: 4px;
}
.tip-content {
  /** 文本3 */
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18.82px;
  color: rgba(212, 48, 48, 1);
  text-align: left;
  vertical-align: top;
}
.mr20 {
  margin-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.username {
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(32, 35, 50, 1);
  width: 50%;
}
.label {
  width: 130px;
  text-align: right;
  display: inline-block;
}
.value1 {
  width: calc(100% - 100px);
  display: inline-block;
  word-wrap: break-word; /* 在长单词或URL地址内部进行换行 */
}
.description {
  line-height: 23.17px;
}
</style>